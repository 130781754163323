;(function($){    
    //pass in just the context as a $(obj) or a settings JS object
    $.fn.autogrow = function(opts) {
        var that = $(this).css({overflow: 'hidden', resize: 'none'}) //prevent scrollies
            , selector = that.selector
            , defaults = {
                context: $(document) //what to wire events to
                , animate: true //if you want the size change to animate
                , speed: 200 //speed of animation
                , fixMinHeight: true //if you don't want the box to shrink below its initial size
                , cloneClass: 'autogrowclone' //helper CSS class for clone if you need to add special rules
                , onInitialize: false //resizes the textareas when the plugin is initialized
            }
        ;
        opts = $.isPlainObject(opts) ? opts : {context: opts ? opts : $(document)};
        opts = $.extend({}, defaults, opts);
        that.each(function(i, elem){
            var min, clone;
            elem = $(elem);
            //if the element is "invisible", we get an incorrect height value
            //to get correct value, clone and append to the body. 
            if (elem.is(':visible') || parseInt(elem.css('height'), 10) > 0) {
                min = parseInt(elem.css('height'), 10) || elem.innerHeight();
            } else {
                clone = elem.clone()
                    .addClass(opts.cloneClass)
                    .val(elem.val())
                    .css({
                        position: 'absolute'
                        , visibility: 'hidden'
                        , display: 'block'
                    })
                ;
                $('body').append(clone);
                min = clone.innerHeight();
                clone.remove();
            }
            if (opts.fixMinHeight) {
                elem.data('autogrow-start-height', min); //set min height                                
            }
            elem.css('height', min);
            
            if (opts.onInitialize && elem.length) {
                resize.call(elem[0]);
            }
        });
        opts.context
            .on('keyup paste', selector, resize)
        ;
    
        function resize (e){
            var box = $(this)
                , oldHeight = box.innerHeight()
                , newHeight = this.scrollHeight
                , minHeight = box.data('autogrow-start-height') || 0
                , clone
            ;
            if (oldHeight < newHeight) { //user is typing
                this.scrollTop = 0; //try to reduce the top of the content hiding for a second
                if(opts.animate) {
                    box.stop().animate({height: newHeight}, {duration: opts.speed, complete: notifyGrown});
                } else {
                    box.innerHeight(newHeight);
                    notifyGrown();
                }
                
            } else if (!e || e.which == 8 || e.which == 46 || (e.ctrlKey && e.which == 88)) { //user is deleting, backspacing, or cutting
                if (oldHeight > minHeight) { //shrink!
                    //this cloning part is not particularly necessary. however, it helps with animation
                    //since the only way to cleanly calculate where to shrink the box to is to incrementally
                    //reduce the height of the box until the $.innerHeight() and the scrollHeight differ.
                    //doing this on an exact clone to figure out the height first and then applying it to the
                    //actual box makes it look cleaner to the user
                    clone = box.clone()
                        //add clone class for extra css rules
                        .addClass(opts.cloneClass)
                        //make "invisible", remove height restriction potentially imposed by existing CSS
                        .css({position: 'absolute', zIndex:-10, height: ''}) 
                        //populate with content for consistent measuring
                        .val(box.val()) 
                    ;
                    box.after(clone); //append as close to the box as possible for best CSS matching for clone
                    do { //reduce height until they don't match
                        newHeight = clone[0].scrollHeight - 1;
                        clone.innerHeight(newHeight);
                    } while (newHeight === clone[0].scrollHeight);
                    newHeight++; //adding one back eliminates a wiggle on deletion 
                    clone.remove();
                    box.focus(); // Fix issue with Chrome losing focus from the textarea.
                    
                    //if user selects all and deletes or holds down delete til beginning
                    //user could get here and shrink whole box
                    newHeight < minHeight && (newHeight = minHeight);
                    if(oldHeight > newHeight) {
                        if(opts.animate) {
                            box.stop().animate({height: newHeight}, {duration: opts.speed, complete: notifyShrunk});
                        } else {
                            box.innerHeight(newHeight);
                            notifyShrunk();
                        }
                    }
                    
                } else { //just set to the minHeight
                    box.innerHeight(minHeight);
                }
            } 
        }

        // Trigger event to indicate a textarea has grown.
        function notifyGrown() {
            opts.context.trigger('autogrow:grow');
        }

        // Trigger event to indicate a textarea has shrunk.
        function notifyShrunk() {
            opts.context.trigger('autogrow:shrink');
        }

        return that;
    }
})(jQuery);



var Layout = new (function(){

    
    this.init = function(){

        $(".needs_login").click(Layout.needs_login)

        $('#btn-menu-mobile').on('click',Layout.menuMobile);

        $(window).resize(Layout.menuMobileCerrar);

        $('.palabra-glosario').on('click', Layout.glosario);

        $('#cerrar-glosario').on('click', Layout.cerrarGlosario);

        $("#form-registro").submit(Layout.register);

        $("#form-solicitud").submit(Layout.service_request);

        $("a.caja-simulador").click(Layout.beauty_box);

        $("input.simulador-number-input").mask("#,##0", {reverse: true, placeholder: "$0,000"});
        $('#cp').mask("00000", { placeholder: "00000"});
        $('.porcentaje').mask("000.00", { placeholder: "00.00 %"});

        $(".fase-2").toggleClass('hide');
        $(".fase-3").toggleClass('hide');
        $("#btn-atras").toggleClass('hide');
        $("#privacidad").parent().css('opacity', 0);  

        $(".api-maps").change(Layout.api_google_maps);

        $("#centro-detalle-cotizador").submit(Layout.prestamos_magisteriales);
        
        $(".link-tabla-amortizacion").click(Layout.tabla_amortizacion);

        $("#telefono").change(Layout.fillPhone);

        $("#contacto-form").submit(Layout.contacto_form);

        $("textarea.input-azul").autogrow();
        
        $("#asunto").change(Layout.show_requeriments);

        $("#adjunto").change(Layout.check_files);
    };   

    this.check_files = function(evt){
        var files = evt.target.files.length;
        var max_files = 15
        if(files > max_files){
            swal({
                title: 'La cantidad máxima de archivos permitidos es ' + max_files +'.',
                type: 'warning'
            });
            $(evt.target).val("");
        }
    }

    this.show_requeriments = function(evt){
        var id = "requirements-" + evt.target.value;
        var requirements = $("#" + id).val();
        $("#requirements-text").text(requirements || 'No especificado.');
    }

    this.fillPhone = function(evt){
        var value = $(this).val();
        $(".telefono-target").each(function(){
            if(this.value == ''){
                this.value = value;
            }
        });
    }

    this.tabla_amortizacion = function(evt){
        evt.preventDefault();

        var data = $("#centro-detalle-cotizador").serialize();
        window.open(this.href + '?' + data,'_blank');
    }

    this.api_google_maps = function(evt){
        if($(this).val().length < 4){
            return;
        }
        var cp = $("#cp");
        var colonia = $("#colonia");
        var direccion = $("#direccion");
        var ciudad = $("#ciudad");
        var municipio = $("#municipio");

        var url = "https://maps.googleapis.com/maps/api/geocode/json?address=";
        var direccion_str = direccion.val() + ", " + colonia.val() + ", " + cp.val() + ', ' + 'México';
        url += direccion_str.replace(/\s+/g,'+');
        url += "&key=AIzaSyBJ7XI-XUTHso3WIRL0cBHpUTm2f4T9D5E";
        var jqxhr = $.get( url, function(response) {
            if(response.status == 'OK'){
                if(response.results.length > 0){
                    results = response.results[0];
                    for(var i in results.address_components){
                        var type = results.address_components[i].types[0];
                        var value = results.address_components[i].short_name;
                        switch(type){
                            case "political":
                                if(colonia.val().length == 0){
                                    colonia.val(value);
                                }
                            break;
                            case "locality": //municipio ciudad
                                if(ciudad.val().length == 0){
                                    ciudad.val(value);
                                }
                                if(municipio.val().length == 0){
                                    municipio.val(value);
                                }
                            break;
                            case "postal_code": //
                                if(cp.val().length == 0){
                                    cp.val(value);
                                }
                            break;
                            
                        }
                    }
                }
            }
        });
    }

    this.prestamos_magisteriales = function(evt){
        evt.preventDefault();
        
        var form = $(this);
        var submit_btn = form.find(':submit');
        submit_btn.attr('disabled','disabled');

        var data = new FormData();
        form.find('input[type=file]').each(function(){
            for(var i in this.files){
                data.append(this.name, this.files[i] );
            }
        });

        $(form.serializeArray()).each(function(){
            data.append(this.name, this.value);
        });


        $.ajax({
            url: form.attr('action'),
            type: form.attr('method'),
            data: data,
            cache: false,
            contentType: false,
            processData: false,
            success: function(response){
                submit_btn.removeAttr('disabled');
                if(response.status){
                    swal({
                        title: response.message,
                        type: 'success'
                    }, function(){
                        if(response.url){
                            window.location = response.url;
                        }
                    });
                }else{
                    swal({
                        title: response.message,
                        type: 'error',
                    });
                }
            }, error: function(e){
                console.log(e);
                submit_btn.removeAttr('disabled');
                swal({
                    title: 'Ha ocurrido un error inesperado. Inténtelo de nuevo.',
                    type: 'error',
                });
            }
        });
    }

    this.continuarRegistro = function(source){

        var privacidad = $("#privacidad").parent();

        var btn_continuar = $("#btn-continuar");
        var btn_atras = $("#btn-atras");

        var fase_1 = $('.fase-1');
        var fase_2 = $('.fase-2');
        var fase_3 = $('.fase-3');

        var isHiddenFase1 = fase_1.hasClass('hide');
        var isHiddenFase2 = fase_2.hasClass('hide');
        var isHiddenFase3 = fase_3.hasClass('hide');


        if(!isHiddenFase1 && isHiddenFase2 && isHiddenFase3){
            fase_1.addClass('hide');
            fase_2.removeClass('hide');
            fase_3.addClass('hide');

            privacidad.css('opacity', 0);

            btn_atras.removeClass('hide');
            btn_continuar.text('Continuar');
        }else if(isHiddenFase1 && !isHiddenFase2 && isHiddenFase3){
            fase_1.addClass('hide');
            fase_2.addClass('hide');
            fase_3.removeClass('hide');

            privacidad.css('opacity', 1);

            btn_atras.removeClass('hide');
            btn_continuar.text('Finalizar');

        }else if(isHiddenFase1 && isHiddenFase2 && !isHiddenFase3){
            $("#form-registro").submit();
        }
        
    }

    this.atrasRegistro = function(source){

        var privacidad = $("#privacidad").parent();

        var btn_continuar = $("#btn-continuar");
        var btn_atras = $("#btn-atras");

        var fase_1 = $('.fase-1');
        var fase_2 = $('.fase-2');
        var fase_3 = $('.fase-3');

        var isHiddenFase1 = fase_1.hasClass('hide');
        var isHiddenFase2 = fase_2.hasClass('hide');
        var isHiddenFase3 = fase_3.hasClass('hide');


        if(isHiddenFase1 && !isHiddenFase2 && isHiddenFase3){
            fase_1.removeClass('hide');
            fase_2.addClass('hide');
            fase_3.addClass('hide');

            privacidad.css('opacity', 0);

            btn_atras.addClass('hide');
            btn_continuar.text('Continuar');

        }else if(isHiddenFase1 && isHiddenFase2 && !isHiddenFase3){
            fase_1.addClass('hide');
            fase_2.removeClass('hide');
            fase_3.addClass('hide');
            btn_continuar.text('Continuar');

            privacidad.css('opacity', 0);
        }
    }

    
    this.add_beneficiario = function(source){
        var _this = $(source);
        var container = _this.parents('div.row-beneficiario');
        var parent = _this.parents('div.beneficiarios-container');
        var new_container = container.clone();

        new_container.find(':input').each(function(){
            $(this).val('');
        });

        new_container.hide();

        parent.append(new_container);

        new_container.slideDown();
    }

    this.remove_beneficiario = function(source){
        var _this = $(source);
        var container = _this.parents('div.row-beneficiario');
        var parent = _this.parents('div.beneficiarios-container');
        
        var beneficiarios_count = parent.find('div.row-beneficiario').length;

        if(beneficiarios_count > 1){
            var buttons = $('.remove-beneficiario');
            buttons.attr('disabled','disabled');
            container.slideUp(function(){
                $(this).remove();
                 buttons.removeAttr('disabled');
            });
        }
    }

    this.beauty_box = function(e){
        
        var _this = $(this);
        if(!_this.hasClass('box-no-action')){
            e.preventDefault();
        }
        else{
            return;
        }
        var container = _this.parent(); 

        container.find('.caja-simulador').each(function(){
            var _this = $(this);
            _this.removeClass('azul');
            _this.addClass('blanco');
            var icon = _this.children(".icon-billete-blanco");
            icon.removeClass('icon-billete-blanco');
            icon.addClass('icon-billete-azul');
        });

        _this.removeClass('blanco');
        _this.addClass('azul');
        var icon = _this.children(".icon-billete-azul");
        icon.removeClass('icon-billete-azul');
        icon.addClass('icon-billete-blanco');


        var plazo = _this.find('.plazo-prestamo').html();
        var dinero = _this.find('.dinero-prestamo').html();

        var target = $(".target-simulador");
        target.removeClass('hide');
        target.text('Has elegido ' + dinero + ' ' + plazo);

        var plazo_number = plazo.replace(" meses","");
        plazo_number = plazo_number.replace("A ","");
        plazo_number = plazo_number.replace("a ","");
        plazo_number = Number(plazo_number) * 2;

        var capital = dinero.replace("$","");
        capital = capital.replace(",","");
        capital = Number(capital);

        $("#plazo_prestamo").val(plazo_number);
        $("#dinero_prestamo").val(capital);

        var interes = $("#tasa-interes").val() / 100;

        var pago_quincenal = ExcelFormulas.PMT(interes/24,plazo_number,capital);
        pago_quincenal = Math.abs(pago_quincenal); 

        $("#pago_quincenal_prestamo").val(pago_quincenal);

        $("#pago-quincenal").html("Tu pago quincenal será de $" + Simuladores.formato(pago_quincenal));

        var seguro_saldo_deudor = $("#seguro_saldo_deudor").val();
        var comision_apertura = $("#comision_apertura").val();
        if(seguro_saldo_deudor && comision_apertura){
            seguro_saldo_deudor = Number(seguro_saldo_deudor);
            comision_apertura = Number(comision_apertura);

            $("#comision-apertura").text(Simuladores.formato(capital * comision_apertura/100)); 
            $("#saldo-deudor").text(Simuladores.formato(capital * seguro_saldo_deudor / 100 * plazo_number / 2 ));
            var suma_intereses = 0;
            for(var i = 1; i <= plazo_number ; i++){
                var interes_ = ExcelFormulas.IPMT(-1*capital,pago_quincenal,interes/24,i);
                suma_intereses += interes_;
            }

            $("#tasa-real-efectiva").text(Simuladores.formato(suma_intereses/(plazo_number/24)/capital*100));
        }else if(seguro_saldo_deudor){
            seguro_saldo_deudor = Number(seguro_saldo_deudor);

            $("#saldo-deudor").text(Simuladores.formato(capital * seguro_saldo_deudor / 100 * plazo_number / 2 ));
        }

    }

    this.needs_login = function(e){
        e.preventDefault();
         swal({
            title: 'Debes estár loggeado para entrar a esta sección.',
            text: '¿Deseas ir a la sección de login?',
            showCancelButton: true,
            confirmButtonText: "Sí",
            cancelButtonText: "No",
            type: 'info',
         },function(isConfirm){
                if (isConfirm) {
                    window.location = $("a.btn-menu-solido:contains('INGRESAR')").attr('href');
                }
            }
        );
    }

    this.contacto_form = function(e){
        e.preventDefault();
        var form = $(this);
        var submit_btn = form.find(':submit');
        submit_btn.attr('disabled','disabled');

        var data = form.serializeArray();
        var is_valid = true;
        for(i in data){
            switch(data[i].name){
                case 'name':
                    var val = data[i].value;
                    if(typeof val == undefined || val.length <= 4){
                        swal({
                            title: 'El nombre es demasiado corto.',
                            type: 'error',
                        });
                        is_valid = false;
                    }
                    break;
                case 'email':
                    var val = data[i].value;
                    if(typeof val == undefined || val.length <= 4){
                        swal({
                            title: 'El correo electrónico es demasiado corto.',
                            type: 'error',
                        });
                        is_valid = false;
                    }
                    break;
                case 'subject':
                    var val = data[i].value;
                    if(typeof val == undefined || val.length <= 4){
                        swal({
                            title: 'El asunto es demasiado corto.',
                            type: 'error',
                        });
                        is_valid = false;
                    }
                    break;
                case 'message':
                    var val = data[i].value;
                    if(typeof val == undefined || val.length <= 10){
                        swal({
                            title: 'El mensaje es demasiado corto.',
                            type: 'error',
                        });
                        is_valid = false;
                    }
                    break;
            }
        }
        if(!is_valid){
            submit_btn.removeAttr('disabled');
            return;
        }

        $.ajax({
            url: form.attr("action"),
            type: form.attr("method"),
            data: data,
            success: function(response){
                console.log(response);
                submit_btn.removeAttr('disabled');
                if(response.status){
                    swal({
                        title: response.message,
                        type: 'success'
                    }, function(){
                        if(response.url){
                            window.location = response.url;
                        }
                    });
                }else{
                    swal({
                        title: response.message,
                        type: 'error',
                    });
                }
            }, error: function(e){
                console.log(e);
                submit_btn.removeAttr('disabled');
                btn_atras.removeAttr('disabled');
                swal({
                    title: 'Ha ocurrido un error inesperado. Inténtelo de nuevo.',
                    type: 'error',
                });
            }
        });
    }

    this.service_request = function(e){
        e.preventDefault();
        var form = $(this);
        var submit_btn = form.find(':submit');
        submit_btn.attr('disabled','disabled');

        var data = form.serializeArray();
        var is_valid = true;
        for(i in data){
            switch(data[i].name){
                case 'service_subject_id':
                    var val = data[i].value;
                    if(isNaN(val) || val <= 0){
                        swal({
                            title: 'Seleccione un asunto.',
                            type: 'warning',
                        });
                        is_valid = false;
                    }
                    break;
                case 'message':
                    var val = data[i].value;
                    if(typeof val == undefined || val.length <= 10){
                        swal({
                            title: 'El mensaje es demasiado corto.',
                            type: 'error',
                        });
                        is_valid = false;
                    }
                    break;
            }
        }
        if(!is_valid){
            submit_btn.removeAttr('disabled');
            return;
        }

        var data_form = new FormData();
        form.find('input[type=file]').each(function(){
            for(var i in this.files){
                data_form.append(this.name, this.files[i] );
            }
        });

        $(form.serializeArray()).each(function(){
            data_form.append(this.name, this.value);
        });
        $.ajax({
            url: form.attr("action"),
            type: form.attr("method"),
            data: data_form,
            cache: false,
            contentType: false,
            processData: false,
            success: function(response){
                console.log(response);
                submit_btn.removeAttr('disabled');
                if(response.status){
                    swal({
                        title: response.message,
                        type: 'success'
                    }, function(){
                        if(response.url){
                            window.location = response.url;
                        }
                    });
                }else{
                    swal({
                        title: response.message,
                        type: 'error',
                    });
                }
            }, error: function(e){
                console.log(e);
                submit_btn.removeAttr('disabled');
                btn_atras.removeAttr('disabled');
                swal({
                    title: 'Ha ocurrido un error inesperado. Inténtelo de nuevo.',
                    type: 'error',
                });
            }
        });
       
    };

    this.register = function(e){
        e.preventDefault();

        var form = $(this);

        var submit_btn = $('#btn-continuar');
        var btn_atras = $("#btn-atras");

        submit_btn.html('<i class="fa fa-spinner fa-pulse fa-fw"></i>');
        btn_atras.attr('disabled','disabled');
        submit_btn.attr('disabled','disabled');

        $.ajax({
            url: form.attr("action"),
            type: form.attr("method"),
            data: form.serialize(),
            success: function(response){
                console.log(response);
                submit_btn.removeAttr('disabled');
                submit_btn.html('Finalizar');
                btn_atras.removeAttr('disabled');
                if(response.status){
                    swal({
                        title: response.message,
                        type: 'success'
                    }, function(){
                        if(response.url){
                            window.location = response.url;
                        }
                    });
                }else{
                    swal({
                        title: response.message,
                        type: 'error',
                    });
                }
            }, error: function(e){
                console.log(e);
                submit_btn.removeAttr('disabled');
                submit_btn.html('Finalizar');
                btn_atras.removeAttr('disabled');
                swal({
                    title: 'Ha ocurrido un error inesperado. Inténtelo de nuevo.',
                    type: 'error',
                });
            }
        });
    };

    this.menuMobile = function(){


        if($( "#contenedor-menu" ).hasClass( "open-menu" ))
        {
            $('#contenedor-menu').removeClass('open-menu');
        }
        else{
            $('#contenedor-menu').addClass('open-menu');
        }

    };

    this.menuMobileCerrar = function(){

        $('#contenedor-menu').removeClass('open-menu');

    };

    this.glosario = function(){

        var scroll = $(window).scrollTop();

        $('#'+ $(this).data('termino')).addClass('ver-termino');
        $('#caja-glosario-blanca').css('top',(scroll+20)+'px');
        
        $('#glosario-oculto').fadeIn('fast');
        
    };

    this.cerrarGlosario = function(){
        
        $('#glosario-oculto').fadeOut('fast');
        $('ver-termino').addClass('ver-termino');
    };
    
})();