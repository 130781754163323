var ExcelFormulas = {

	PVIF: function(rate, nper) {
		return Math.pow(1 + rate, nper);
	},

	FVIFA: function(rate, nper) {
		return rate == 0? nper: (this.PVIF(rate, nper) - 1) / rate;
	},	

	PMT: function(rate, nper, pv, fv, type) {
		if (!fv) fv = 0;
		if (!type) type = 0;

		if (rate == 0) return -(pv + fv)/nper;
		
		var pvif = Math.pow(1 + rate, nper);
		var pmt = rate / (pvif - 1) * -(pv * pvif + fv);

		if (type == 1) {
			pmt /= (1 + rate);
		};

		return pmt;
	},

	IPMT: function(pv, pmt, rate, per) {
		var tmp = Math.pow(1 + rate, per);
		return 0 - (pv * tmp * rate + pmt * (tmp - 1));
	},

	PPMT: function(rate, per, nper, pv, fv, type) {
		if (per < 1 || (per >= nper + 1)) return null;
		var pmt = this.PMT(rate, nper, pv, fv, type);
		var ipmt = this.IPMT(pv, pmt, rate, per - 1);
		return pmt - ipmt;
	},
	
	DaysBetween: function(date1, date2) {
		var oneDay = 24*60*60*1000;
		return Math.round(Math.abs((date1.getTime() - date2.getTime())/oneDay));
	},
	
	// Change Date and Flow to date and value fields you use
	XNPV: function(rate, values) {
		var xnpv = 0.0;
		var firstDate = new Date(values[0].Date);
		for (var key in values) {
			var tmp = values[key];
			var value = tmp.Flow;
			var date = new Date(tmp.Date);
			xnpv += value / Math.pow(1 + rate, this.DaysBetween(firstDate, date)/365);
		};
		return xnpv;
	},

	XIRR: function(values, guess) {
		if (!guess) guess = 0.1;
		
		var x1 = 0.0;
		var x2 = guess;
		var f1 = this.XNPV(x1, values);
		var f2 = this.XNPV(x2, values);
		
		for (var i = 0; i < 100; i++) {
			if ((f1 * f2) < 0.0) break;
			if (Math.abs(f1) < Math.abs(f2)) {
				f1 = this.XNPV(x1 += 1.6 * (x1 - x2), values);
			}
			else {
				f2 = this.XNPV(x2 += 1.6 * (x2 - x1), values);
			}
		};
		
		if ((f1 * f2) > 0.0) return null;
		
		var f = this.XNPV(x1, values);
		if (f < 0.0) {
			var rtb = x1;
			var dx = x2 - x1;
		}
		else {
			var rtb = x2;
			var dx = x1 - x2;
		};
		
		for (var i = 0; i < 100; i++) {
			dx *= 0.5;
			var x_mid = rtb + dx;
			var f_mid = this.XNPV(x_mid, values);
			if (f_mid <= 0.0) rtb = x_mid;
			if ((Math.abs(f_mid) < 1.0e-6) || (Math.abs(dx) < 1.0e-6)) return x_mid;
		};
		
		return null;
	}

};

var Simuladores = new (function () {

    this.config = {
        monto_seguro_de_vida: 391102.22,
        cuota_sv_quincenal: 412.55,
        cuota_jr_quincenal: 12555.22,
        cuota_ch_quincenal: 912.55,
        tasa_interes_fideicomiso: 21.12,
        maximo_monto_magisterial: 150000 , 
        maximo_meses_magisterial: 60,
        monto_max_ch: 35952.00, 
        plazo_ch: 48,
    };

    this.seguro = function (monto_seguro_de_vida, cuota_sv_quincenal) {
        
        Simuladores.config.monto_seguro_de_vida = monto_seguro_de_vida || Simuladores.config.monto_seguro_de_vida;
        Simuladores.config.cuota_sv_quincenal = cuota_sv_quincenal || Simuladores.config.cuota_sv_quincenal;

        $('#btn-calcular').on('click', Simuladores.calcularSeguroVida);
        $('#btn-borrar').on('click', Simuladores.limpiar);
    };

    this.credito = function (tasa_interes_fideicomiso, cuota_sv_quincenal, cuota_jr_quincenal, cuota_ch_quincenal) {

        Simuladores.config.tasa_interes_fideicomiso = tasa_interes_fideicomiso || Simuladores.config.tasa_interes_fideicomiso;
        Simuladores.config.cuota_sv_quincenal = cuota_sv_quincenal || Simuladores.config.cuota_sv_quincenal;
        Simuladores.config.cuota_jr_quincenal = cuota_jr_quincenal || Simuladores.config.cuota_jr_quincenal;
        Simuladores.config.cuota_ch_quincenal = cuota_ch_quincenal || Simuladores.config.cuota_ch_quincenal;

        $('#adhesion').hide();
        $('#btn-calcular').on('click', Simuladores.calcularCredito);

        var borrarCustom = function(evt){
            $("#capital").val('');
            $("#plazo").val('');
            $("#pago_quincenal").val('');
            $("#sueldo_quincenal").val('');

            $('.clear-porcentaje').each(function(){
                if(this.children.length == 0){
                    $(this).html('0.00 %');
                }
            });
            $('.clear-pesos').each(function(){
                if(this.children.length == 0){
                    $(this).html('$0.00'); 
                }
            }); 

            $('.clear-plazo').each(function(){
                if(this.children.length == 0){
                    $(this).html(''); 
                }
            }); 
        }; 

        $('#btn-borrar').on('click', borrarCustom);
        $("#btn-borrar-adhesion").on('click', borrarCustom);

        $('#btn-calcular-adhesion').on('click', Simuladores.calcularCredito.adhesion);

        $("#plazo").mask("000", {placeholder: "000"});
    };
    
    this.prestamoMagistral = function (maximo_monto_magisterial, maximo_meses_magisterial) {
        $('#adhesion2').hide();

        Simuladores.config.maximo_monto_magisterial = maximo_monto_magisterial || Simuladores.config.maximo_monto_magisterial;
        Simuladores.config.maximo_meses_magisterial = maximo_meses_magisterial || Simuladores.config.maximo_meses_magisterial;

        $('#btn-calcular').on('click', Simuladores.calcularPrestamoMagistral);
    };

    this.cajaAhorros = function (monto, plazo) {
        Simuladores.config.monto_max_ch = monto || Simuladores.config.monto_max_ch 
        Simuladores.config.plazo_ch = plazo || Simuladores.config.plazo_ch;
        $('#btn-calcular').on('click', Simuladores.calcularCajaAhorros);
    };


    this.limpiar = function () {
        $('.clear-porcentaje').html('0.00 %');
        $('.clear-pesos').html('$0.00');
        $('#monto').val('');
        $('#cuota').val('');
    };

    this.porcentajeDiferencia = function(max, min) {
        return (min / max) * 100;
    };

    this.formato = function (n) {
        if (n != '') {
            n = n.toFixed(2);
            var number = n.toString();
            var decimales = number.substr(number.length - 2);
            number = number.substr(0, number.length - 3);

            var result = '';
            while (number.length > 3) {
                result = ',' + number.substr(number.length - 3) + result;
                number = number.substring(0, number.length - 3);
            }

            result = number + result + '.' + decimales;
            return result;
        }
        else {
            return '0.00';
        }
    };


    this.calcularSeguroVida = function () {
        var importeMontoSeguroVidaFIDEICOMISO = Simuladores.config.monto_seguro_de_vida;
        var importeCuotaQuincenalFIDEICOMISO = Simuladores.config.cuota_sv_quincenal;

        var importeMontoSeguroVidaSituacionActual = Number($('#monto').cleanVal());
        var importeCuotaQuincenalSituacionActual = Number($('#cuota').cleanVal());

        if (isNaN(importeMontoSeguroVidaSituacionActual) || isNaN(importeCuotaQuincenalSituacionActual)) {
            swal({
                title: 'Debe ingresar el monto y la cuota.',
                type: 'warning',
            });
        }
        else {
            /* Monto del seguro de vida*/
            $('#monto-2').html('$' + Simuladores.formato(importeMontoSeguroVidaFIDEICOMISO));
            $('#monto-3').html('$' + Simuladores.formato((importeMontoSeguroVidaSituacionActual - importeMontoSeguroVidaFIDEICOMISO)));
            $('#monto-4').html(Simuladores.formato(((importeMontoSeguroVidaSituacionActual - importeMontoSeguroVidaFIDEICOMISO) / importeMontoSeguroVidaFIDEICOMISO) * 100) + '%');


            /*Cuota quincenal */
            $('#cuota-2').html('$' + Simuladores.formato(importeCuotaQuincenalFIDEICOMISO));
            $('#cuota-3').html('$' + Simuladores.formato((importeCuotaQuincenalSituacionActual - importeCuotaQuincenalFIDEICOMISO)));
            $('#cuota-4').html(Simuladores.formato(((importeCuotaQuincenalSituacionActual - importeCuotaQuincenalFIDEICOMISO) / importeCuotaQuincenalFIDEICOMISO) * 100) + '%');

            /*Costo anual de la póliza */
            var importeCostoAnualPolizaSituacionActual = importeCuotaQuincenalSituacionActual * 24;
            var importeCostoAnualPolizaFIDEICOMISO = importeCuotaQuincenalFIDEICOMISO * 24;
            var variacionNominalImporteCostoAnualPoliza = importeCostoAnualPolizaSituacionActual - importeCostoAnualPolizaFIDEICOMISO;
            var variacionPorcentualImporteCostoAnualPoliza = (variacionNominalImporteCostoAnualPoliza / importeCostoAnualPolizaFIDEICOMISO) * 100;

            $('#anual-1').html('$' + Simuladores.formato(importeCostoAnualPolizaSituacionActual));
            $('#anual-2').html('$' + Simuladores.formato(importeCostoAnualPolizaFIDEICOMISO));
            $('#anual-3').html('$' + Simuladores.formato(variacionNominalImporteCostoAnualPoliza));
            $('#anual-4').html(Simuladores.formato(variacionPorcentualImporteCostoAnualPoliza) + '%');

            /*Costo anual del seguro por cada $1000 mxn */
            var importeCostoAnualSeguroSituacionActual = (importeCostoAnualPolizaSituacionActual / importeMontoSeguroVidaSituacionActual) * 1000;
            var importeCostoAnualFIDEICOMISO = (importeCostoAnualPolizaFIDEICOMISO / importeMontoSeguroVidaFIDEICOMISO) * 1000;
            var variacionNominalImporteCostoAnual = importeCostoAnualSeguroSituacionActual - importeCostoAnualFIDEICOMISO;
            var variacionPorcentualImporteCostoAnual = (variacionNominalImporteCostoAnual / importeCostoAnualFIDEICOMISO) * 100;

            $('#anual-1-1').html('$' + Simuladores.formato(importeCostoAnualSeguroSituacionActual));
            $('#anual-1-2').html('$' + Simuladores.formato(importeCostoAnualFIDEICOMISO));
            $('#anual-1-3').html('$' + Simuladores.formato(variacionNominalImporteCostoAnual));
            $('#anual-1-4').html(Simuladores.formato(variacionPorcentualImporteCostoAnual) + '%');

            /*Costo mensual del seguro por cada $1000 mxn*/
            var importeCostoMensualSituacionActual = Simuladores.formato((importeCostoAnualSeguroSituacionActual / 12));
            var importeCostoMensualFIDEICOMISO = Simuladores.formato((importeCostoAnualFIDEICOMISO / 12));
            var variacionNominalImporteCostoMensual = Simuladores.formato((importeCostoMensualSituacionActual - importeCostoMensualFIDEICOMISO));
            var variacionPorcentualImporteCostoMensual = Simuladores.formato((variacionNominalImporteCostoMensual / importeCostoMensualFIDEICOMISO) * 100);

            $('#mensual-1').html('$' + importeCostoMensualSituacionActual);
            $('#mensual-2').html('$' + importeCostoMensualFIDEICOMISO);
            $('#mensual-3').html('$' + variacionNominalImporteCostoMensual);
            $('#mensual-4').html(variacionPorcentualImporteCostoMensual + '%');
        }
    };

    this.TasaInteres = function(importeCapital, importePagoQuincenal, plazoQuincenas) {

        var tasaInteresMasCercana = 0;
        var diferenciaMasCercana = 99999999;
        var tasaInteres = 0.0001;

        while (true)
        {
            var capitalCalculado = 1;
            var tasaQuincenal = tasaInteres / 24;

            // B10 * ((1-(1+B30)^-B8)/B30)
            capitalCalculado = importePagoQuincenal * ((1 - Math.pow(1 + tasaQuincenal, -plazoQuincenas)) / (tasaQuincenal));

            var diferencia = capitalCalculado - importeCapital;

            if (Math.abs(diferencia) < diferenciaMasCercana)
            {
                diferenciaMasCercana = Math.abs(diferencia);
                tasaInteresMasCercana = tasaInteres;
            }

            if (capitalCalculado == importeCapital || capitalCalculado == 0)
            {
                break;
            }
            else
            {
                if (capitalCalculado < importeCapital)
                {
                    break;
                }
            }

            tasaInteres += 0.0001;
        }

        return tasaInteresMasCercana;
    };

    

    this.calcularCredito = function () {
        var importeCapital = parseInt($('#capital').cleanVal());
        var plazoQuincenas = parseInt($('#plazo').cleanVal());
        var importePagoQuincenal = parseInt($('#pago_quincenal').cleanVal());
        var importeSueldoLiquidoQuincenal = parseInt($('#sueldo_quincenal').cleanVal());

        var tasaInteresFideicomiso = Simuladores.config.tasa_interes_fideicomiso;
        var tasaInteres = Simuladores.TasaInteres(importeCapital, importePagoQuincenal, plazoQuincenas) * 100;
        var tasaInteresQuincenal = ( tasaInteresFideicomiso / 100 ) / 24;


        var factorPagoFijo = tasaInteresQuincenal / ( 1 - ( 1 / ( Math.pow(1 + tasaInteresQuincenal, plazoQuincenas) ) ) );
        var importePagoQuincenalPrograma = importeCapital * factorPagoFijo;

        var importePagoTotal = plazoQuincenas * importePagoQuincenal;
        var importePagoIntereses = importePagoTotal - importeCapital;
        var importePagoTotalPrograma = plazoQuincenas * importePagoQuincenalPrograma;
        var importePagoInteresesPrograma = importePagoTotalPrograma - importeCapital;
        var sueldoLiquidoQuincenalPrograma = importeSueldoLiquidoQuincenal - importePagoQuincenalPrograma + importePagoQuincenal;

        var importeQuincenalNominal = importePagoQuincenalPrograma - importePagoQuincenal;
        var importeTotalNominal = importePagoTotalPrograma - importePagoTotal;
        var sueldoLiquidoQuincenalNominal = importeSueldoLiquidoQuincenal - sueldoLiquidoQuincenalPrograma;

        var importeVPQuincenal = importePagoQuincenalPrograma - importePagoQuincenal;

        var porcentajeVPQuincenal = ( importeVPQuincenal / importePagoQuincenal ) * 100;
        var importeVPTotal = importePagoTotalPrograma - importePagoTotal;
        var porcentajeVPTotal = (importeVPTotal / importePagoTotal) * 100;
        var importeVPIntereses = importePagoInteresesPrograma - importePagoIntereses;
        var porcentajeVPIntereses = (importeVPIntereses / importePagoIntereses) * 100;
        var importeVariacionSueldoLiquido = sueldoLiquidoQuincenalPrograma - importeSueldoLiquidoQuincenal;
        var porcentajeVariacionSueldoLiquido = (importeVariacionSueldoLiquido / importeSueldoLiquidoQuincenal) * 100;


        if (isNaN(importeCapital) || isNaN(plazoQuincenas) || isNaN(importePagoQuincenal) || isNaN(importeSueldoLiquidoQuincenal) ) {
            swal({
                title: 'Debe ingresar el capital, plazo por transcurrir (quincenas), pago quincenal y sueldo líquido quincenal.',
                type: 'warning',
            });
        }else if((plazoQuincenas * importePagoQuincenal) < importeCapital){
            // Plazo por trascurrir ( quincenas ) + Pago quincenal >= Capital.
            swal({
                title: 'El importe de pago quincenal y el plazo especificados no cubren el total del adeudo.',
                type: 'warning', 
            });
        }
        else {
            // console.log(tasaInteresQuincenal, importePagoTotal, importePagoIntereses, importePagoTotalPrograma, importePagoInteresesPrograma, sueldoLiquidoQuincenalPrograma);
            $('#adhesion').show();
            /* Capital */
            $('#total-1').html('$' + Simuladores.formato(importePagoTotal));

            $('#intereses-1').html('$' + Simuladores.formato(importePagoIntereses));
            $('#interes-1').html(Simuladores.formato( tasaInteres ) + '%');
            $('#pago_quincenal-1').html('$' + Simuladores.formato(importePagoQuincenalPrograma));

            $('#capital-2').html('$' + Simuladores.formato(importeCapital));
            $('#total-2').html('$' + Simuladores.formato(importePagoTotalPrograma));
            $('#intereses-2').html('$' + Simuladores.formato(importePagoInteresesPrograma));
            $('#interes-2').html(tasaInteresFideicomiso + '%');
            $('#sueldo_quincenal-2').html('$' + Simuladores.formato(sueldoLiquidoQuincenalPrograma));

            $('#plazo-2').html(plazoQuincenas);
            $('#pago_quincenal-2').html('$' + Simuladores.formato(importeQuincenalNominal));

            $('#total-3').html('$' + Simuladores.formato(importeTotalNominal));
            $('#intereses-3').html('$' + Simuladores.formato(importeTotalNominal));
            $('#interes-3').html(Simuladores.formato(tasaInteresFideicomiso - tasaInteres) + '%');
            $('#sueldo_quincenal-3').html('$' + Simuladores.formato(Math.abs(sueldoLiquidoQuincenalNominal)));
            $('#pago_quincenal-3').html(Simuladores.formato(Simuladores.porcentajeDiferencia(importePagoQuincenal, importeQuincenalNominal)) + '%');

            $('#total-4').html(Simuladores.formato(Simuladores.porcentajeDiferencia(importePagoTotal, importeTotalNominal)) + '%');
            $('#intereses-4').html(Simuladores.formato(Simuladores.porcentajeDiferencia(importePagoIntereses, importeTotalNominal)) + '%');
            $('#interes-4').html(Simuladores.formato(Simuladores.porcentajeDiferencia(tasaInteres, tasaInteresFideicomiso) - 100) + '%');
            $('#sueldo_quincenal-4').html(Simuladores.formato(Simuladores.porcentajeDiferencia(importeSueldoLiquidoQuincenal, sueldoLiquidoQuincenalNominal)) + '%');

            $('#importeIncrementoLiquidez').data('sueldoLiquidoQuincenalNominal',Math.abs(sueldoLiquidoQuincenalNominal));
        }
    };

    this.calcularCredito.adhesion = function() {
        var adhesionSueldoBase = Number($('#adhesionSueldoBase').cleanVal());
        
        var importeCuotaSV = Simuladores.config.cuota_sv_quincenal;
        var importeCuotaJR = Simuladores.config.cuota_jr_quincenal;
        var importeIncrementoLiquidez = Number($('#importeIncrementoLiquidez').data('sueldoLiquidoQuincenalNominal'));
        //var importeCuotaCH = ((adhesionSueldoBase * 0.05) / 2);
        var importeCuotaCH = Simuladores.config.cuota_ch_quincenal;
        var importeLiquidezPermanente = importeIncrementoLiquidez - importeCuotaSV - importeCuotaJR - importeCuotaCH;

        console.log(importeIncrementoLiquidez);
        if (adhesionSueldoBase === '' || isNaN(adhesionSueldoBase) === true) {
            swal({
                title: 'Debe ingresar sueldo mensual base.',
                type: 'warning',
            });
        } else {

            $('#importeIncrementoLiquidez').html('$' + Simuladores.formato(importeIncrementoLiquidez));
            $('#importeCuotaSV').html('$' + Simuladores.formato(importeCuotaSV));
            $('#importeCuotaJR').html('$' + Simuladores.formato(importeCuotaJR));
            $('#importeCuotaCH').html('$' + Simuladores.formato(importeCuotaCH));
            $('#importeLiquidezPermanente').html('$' + Simuladores.formato(importeLiquidezPermanente));
            
        }
    }; 


    this.calcularPrestamoMagistral = function () {
        var importeSueldo = Number($('#importeSueldo').cleanVal());
        var importePagoQuincenal = Number($('#importePagoQuincenal').cleanVal());

        var opcion_36 = $("#prestamo-magisterial-36");
        var opcion_48 = $("#prestamo-magisterial-48");
        var opcion_60 = $("#prestamo-magisterial-60");


        if (isNaN(importeSueldo) === true || isNaN(importePagoQuincenal) === true) {
            swal({
                title: 'Debe ingresar el Sueldo quincenal líquido y descuento quincenal de su préstamo actual.',
                type: 'warning',
            });
        }
        else { 
           var monto_3 = $("#prestamo-magisterial-3-monto");
           var monto_2 = $("#prestamo-magisterial-2-monto");
           var monto_1 = $("#prestamo-magisterial-1-monto");
           var mes_3 = $("#prestamo-magisterial-3-mes");
           var mes_2 = $("#prestamo-magisterial-2-mes");
           var mes_1 = $("#prestamo-magisterial-1-mes");
           
        
           mes_3.html("A " + Simuladores.config.maximo_meses_magisterial + " meses");
           mes_2.html("A " + (Simuladores.config.maximo_meses_magisterial - 12) + " meses");
           mes_1.html("A " + (Simuladores.config.maximo_meses_magisterial - 24) + " meses");


           var maximo_pago_quincenal = (importeSueldo + importePagoQuincenal) * .3;

           var meses = [
               Simuladores.config.maximo_meses_magisterial,
               Simuladores.config.maximo_meses_magisterial - 12,
               Simuladores.config.maximo_meses_magisterial - 24,
           ];


           var montos = [];

           for(var i in meses){
               var pago = 0;
               montos[i] = 0;               
               var interes = (Simuladores.config.tasa_interes_fideicomiso/100)/24;
               do{
                    montos[i] += 500;
                    pago = ExcelFormulas.PMT(interes,meses[i] * 2 ,montos[i]);
                    pago = Math.abs(pago); 
               }while(pago <= maximo_pago_quincenal);
               montos[i] -= 500;
               montos[i] = montos[i] > Simuladores.config.maximo_monto_magisterial ?  Simuladores.config.maximo_monto_magisterial : montos[i];
               montos[i] = Number(montos[i]);
               
               
           }

            monto_3.html("$" + Simuladores.formato(montos[0]));
            monto_2.html("$" + Simuladores.formato(montos[1]));
            monto_1.html("$" + Simuladores.formato(montos[2]));

            $("#caja-simulador-main").click();
            $('#adhesion2').show();
        }
    }

    this.calcularCajaAhorros = function () {
        var importeSueldoLiquido = Number($('#importeSueldoLiquido').cleanVal());
        var importeSueldoMensual = Number($('#importeSueldoMensual').cleanVal());


        if (isNaN(importeSueldoLiquido) === true || isNaN(importeSueldoMensual) === true) {
            swal({
                title: 'Debe ingresar el Sueldo quincenal líquido, Cpto Sueldo Mensual.',
                type: 'warning',
            });
        }
        else {
            // console.log(tasaInteresQuincenal, importePagoTotal, importePagoIntereses, importePagoTotalPrograma, importePagoInteresesPrograma, sueldoLiquidoQuincenalPrograma);
            $('#adhesion').show();
            // /* Capital */
            var generacion = [];
            var mesesPrestar = Simuladores.config.plazo_ch;
            var plazoMeses = Number(Simuladores.config.plazo_ch/2);
            //var montoMaximo =  importeSueldoMensual * mesesPrestar;
            var montoMaximo = Number(Simuladores.config.monto_max_ch);
            
            var plazos_meses = [];
            plazos_meses.push(plazoMeses - 6);
            plazos_meses.push(plazoMeses - 12);
            plazos_meses.push(plazoMeses - 18);

            var plazo_1 = $("#caja-ahorro-48-meses").find('.plazo-prestamo').html("A " + plazoMeses + " meses");
            if(plazos_meses[0] > 0){
                var plazo_2 = $("#caja-ahorro-36-meses").find('.plazo-prestamo').html("A " + plazos_meses[0] + " meses");    
            }else{
                $("#caja-ahorro-36-meses").hide();
            }
            if(plazos_meses[1] > 0){
                var plazo_3 = $("#caja-ahorro-24-meses").find('.plazo-prestamo').html("A " + plazos_meses[1]  + " meses");
            }else{
                $("#caja-ahorro-24-meses").hide();
            }
            if(plazos_meses[2] > 0){
                var plazo_4 = $("#caja-ahorro-12-meses").find('.plazo-prestamo').html("A " + plazos_meses[2] + " meses");
            }else{
                $("#caja-ahorro-12-meses").hide();
            }
            

            var caja_48 = $("#caja-ahorro-48-meses");
            

            var dinero_48 = caja_48.find('h2');
            var dinero_36 = $("#caja-ahorro-36-meses").find('h2');
            var dinero_24 = $("#caja-ahorro-24-meses").find('h2');
            var dinero_12 = $("#caja-ahorro-12-meses").find('h2');

            $('.plazoMeses').html(plazoMeses);
            $('.montoMaximo').html('$' + Simuladores.formato(montoMaximo));

            dinero_48.html('$' + Simuladores.formato(montoMaximo));
            dinero_36.html('$' + Simuladores.formato(montoMaximo * plazos_meses[0] / plazoMeses));
            dinero_24.html('$' + Simuladores.formato(montoMaximo * plazos_meses[1] / plazos_meses[0]));
            dinero_12.html('$' + Simuladores.formato(montoMaximo * plazos_meses[2] / plazos_meses[1]));

            caja_48.click();

            var pago_quincenal = $("#pago-quincenal").html().replace('Tu pago quincenal será de $','');

            var maximo = (importeSueldoLiquido * .3) / 2;

            if(pago_quincenal > maximo){
                Simuladores.config.monto_max_ch - 500; 
                Simuladores.cajaAhorros();
            }
        }
    }
})();